<template>
	<div class="section_body section_join3">
		<div class="section_content">
			<div class="com_stepper">
				<ul>
					<li class="complete"><i>step1</i></li>
					<li class="complete"><i>step2</i></li>
					<li class="complete"><i>step3</i></li>
				</ul>
			</div>

			<div class="wrap_full_content">
				<p class="txt_big">다우오피스 멤버십 신청이 완료되었습니다.</p>
				<p class="img_character" title="안녕하세요, 다우오피스에 오신 것을 환영합니다."></p>
				<p class="desc_22_400">
					사이트 생성은 <span class="em">최소 3분~최대 2시간</span> 정도 소요됩니다.<br />
					개설이 완료되면 메일과 문자로 안내드리겠습니다.<br />
					잠시만 기다려주세요!
				</p>
				<p class="desc">문의 : 1577-3019 / daouoffice@daou.com</p>
			</div>

			<div class="com_form_large form_join3">
				<payment-info></payment-info>
				<site-basic-info></site-basic-info>
				<manager-info></manager-info>
				<order-info></order-info>
				<div class="wrap_function">
					<button class="major large" @click="complete">완료</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PaymentInfo from '@/components/apply/resultInfo/PaymentInfo.vue';
import OrderInfo from '@/components/apply/resultInfo/OrderInfo.vue';
import SiteBasicInfo from '@/components/apply/resultInfo/SiteBasicInfo.vue';
import ManagerInfo from '@/components/apply/resultInfo/ManagerInfo.vue';
export default {
	created() {
		window.scrollTo(0, 0);
	},
	components: {
		PaymentInfo,
		ManagerInfo,
		SiteBasicInfo,
		OrderInfo,
	},
	methods: {
		complete() {
			this.$store.commit('clearAllApplyInfo');
			this.$router.push(`/apply/step1`);
		},
	},
};
</script>

<style></style>
