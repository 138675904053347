<template>
	<div class="wrap_row">
		<div class="row">
			<label class="form_tit">
				<span class="txt_large">개설 정보</span>
			</label>
			<div class="form_data">
				<div class="com_form">
					<div class="wrap_row wrap_data_mini">
						<div class="row">
							<label class="form_tit cell"><span class="txt">상호명</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyInfo.corporateName }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">사업자등록번호</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyInfo.businessLicenseNum }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">사업자등록증</span></label>
							<div class="form_data cell">
								<span class="txt" v-if="this.$store.getters.getBusinessRegistration.length > 0">첨부 </span>
								<span class="txt" v-else>미첨부 </span>
							</div>
						</div>

						<div class="row">
							<label class="form_tit cell"><span class="txt">세금계산서</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getPayMethodInfo.issuedBill ? '발행' : '미발행' }} </span>
							</div>
						</div>
						<div class="row" v-if="this.$store.getters.getAllCompanyInfo.companyInfo.ceoName">
							<label class="form_tit cell"><span class="txt">대표자명</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyInfo.ceoName }}</span>
							</div>
						</div>
						<div class="row" v-if="this.$store.getters.getAllCompanyInfo.companyInfo.directPhone">
							<label class="form_tit cell"><span class="txt">대표번호</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyInfo.directPhone }}</span>
							</div>
						</div>
						<div class="row" v-if="this.$store.getters.getAllCompanyInfo.companyInfo.companyAddress">
							<label class="form_tit cell"><span class="txt">주소</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyInfo.companyAddress }}</span>
							</div>
						</div>
						<!--<div
							class="row"
							v-if="
								this.$store.getters.getAllCompanyInfo.companyInfo.companyZipCode
							"
						>
							<label class="form_tit cell"
								><span class="txt">우편번호</span></label
							>
							<div class="form_data cell">
								<span class="txt">{{
									this.$store.getters.getAllCompanyInfo.companyInfo
										.companyZipCode
								}}</span>
							</div>
						</div>-->
						<div class="row" v-if="this.$store.getters.getAllCompanyInfo.companyInfo.businessCondition">
							<label class="form_tit cell"><span class="txt">업태</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyInfo.businessCondition }}</span>
							</div>
						</div>
						<div class="row" v-if="this.$store.getters.getAllCompanyInfo.companyInfo.businessType">
							<label class="form_tit cell"><span class="txt">종목</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyInfo.businessType }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">회사명(사이트명)</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.siteName }}</span>
							</div>
						</div>
						<div class="row" v-if="this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.domain">
							<label class="form_tit cell"><span class="txt">메일 도메인</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.domain }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">접속 URL</span></label>
							<div class="form_data cell">
								<span class="txt"
									>[{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.name }}]
									{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.url }}.daouoffice.com</span
								>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt"> 관리자 ID</span></label>
							<div class="form_data cell">
								<span class="txt">
									[{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.name }}]
									{{ this.$store.getters.getAllCompanyInfo.companySiteInfo.basicItem.masterId }}</span
								>
							</div>
						</div>
						<template v-for="(item, index) in this.$store.getters.getAllCompanyInfo.companySiteInfo.addonItem">
							<div class="row" :key="`B-${index}`">
								<label class="form_tit cell"><span class="txt">관리자 ID</span></label>
								<div class="form_data cell">
									<span class="txt">[{{ item.name }}] {{ item.masterId }}</span>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
