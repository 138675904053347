<template>
	<div class="wrap_row">
		<div class="row">
			<label class="form_tit">
				<span class="txt_large">담당자 정보</span>
			</label>
			<div class="form_data">
				<div class="com_form">
					<div class="wrap_row wrap_data_mini">
						<div class="row">
							<label class="form_tit cell"><span class="txt">담당자명</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.name }}</span>
							</div>
						</div>
						<div class="row" v-if="this.$store.getters.getAllCompanyInfo.companyManagerInfo.grade">
							<label class="form_tit cell"><span class="txt">직급</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.grade }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">연락처(휴대폰)</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.phone }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">연락처(유선번호)</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.tel }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">이메일</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.email }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">세금계산서 담당자명</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.issuedBillName }}</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">세금계산서 이메일</span></label>
							<div class="form_data cell">
								<span class="txt">{{ this.$store.getters.getAllCompanyInfo.companyManagerInfo.issuedBillEmail }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
